@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-spin-slow {
    animation: spin 3s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .font-montserrat {
    font-family: "Montserrat", sans-serif;
  }
}
